// design tokens come in here, get ready for the ride...

@import '../../../Style/app';

// component style

.Avatar {

    $regular-size: 48px;

    width: $regular-size;
    height: $regular-size;
    min-width: $regular-size;
    min-height: $regular-size;
    border-radius: 48px;
    position: relative;
    @include setColor('background-color','avatar','ocean');

    img {
        height: 1.1 * $regular-size;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    &__loader {
        border: solid 2px $theme-avatar-canary-color;
        border-radius: 48px;
        position: absolute;
        top: 0;
        left: 0;
        animation: blinking 1s infinite;

        @keyframes blinking {
            0% {
                opacity: .3;
            }
            50% {
                opacity: 1;
            }
            100% {
                opacity: .3;
            }
        }
    }

    // attributes: size

    @mixin avatar-width($new-size) {

        $loader-spacing: 12px;

        width: $new-size;
        height: $new-size;
        min-width: $new-size;
        min-height: $new-size;
        border-radius: $new-size;

        .Avatar__loader {
            width: $new-size + $loader-spacing;
            height: $new-size + $loader-spacing;
            min-width: $new-size + $loader-spacing;
            min-height: $new-size + $loader-spacing;
            border-radius: $new-size + $loader-spacing;
            top: -6px;
            left: -6px;
        }
    }

    &--large {
        @include avatar-width(2*$regular-size);

        img {
            height: 112px;
        }
    }

    &--medium {
        @include avatar-width(1.5*$regular-size);
        
        img {
            height: 84px;
        }
    }

    &--small {
        @include avatar-width(.5*$regular-size);

        img {
            height: 18px;
        }
    }

    // attributes: color

    @mixin setThemeColor ($id,$colorToken,$state) {
        &--#{$id} {
            @include setColor('background-color',$colorToken,$state);
        }
    }

    @include setThemeColor('sky','avatar','sky');
    @include setThemeColor('sunset','avatar','sunset');
    @include setThemeColor('canary','avatar','canary');
    @include setThemeColor('ocean','avatar','ocean');
    @include setThemeColor('sunrise','avatar','sunrise');
    @include setThemeColor('grass','avatar','grass');
    @include setThemeColor('success','success','s100');

    // attributes: elevation

    &--elevation {
        @include setElevation('default','base');
    } 
}

.Avatar__link {
    cursor: pointer;
}