// design tokens come in here, get ready for the ride...

@import '../../Style/app';

// component style

.Loader {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
    @include setColor('background-color','avatar','sky');
    
    h1, h2, h3, p {
        width: 100%;
        margin-bottom: getWhitespace('margin','x3');
        padding: 0 getWhitespace('margin','x3');
        text-align: center;
        @include setColor('color','base','s100');
    }

    [class*='Avatar'] {
        margin: auto;
    }

    [class*='Grid'] {
        //margin-top: -4 * $theme-grid-ws-base;
    }

    // attributes: landscape

    &--city {
    }

    &--beach {
    }

    &--transport {
    }

    &--bike {
    }
}

.CardLoader {
    @include setColor('background-color','avatar','sky');
    position: relative;
    padding: getWhitespace('padding','x3') 0;

    &__Body {
        width: 100%;
        display: flex;
        flex-flow: column;
        align-items: center;
        text-align: center;
    }
}

.Spinner {
    width: 2rem;
    height: 2rem;
    border-top-color: getColor('secondary','s100');
    border-left-color: getColor('secondary','s50');
    border-bottom-color: getColor('secondary','s50');
    border-right-color: getColor('secondary','s50');
    animation: spinner 500ms linear infinite;
    border-style: solid;
    border-width: 4px;
    border-radius: 50%;  
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
    margin: getWhitespace('margin','x1') 0 getWhitespace('margin','x2');

    @keyframes spinner {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
}

.Sky {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    background-image: url('../../Assets/landscapes/sun.svg');
    background-size: auto 30px;
    background-position: 95% 20%;
    background-repeat: no-repeat;
    overflow: -moz-hidden-unscrollable;
    z-index: 0;
}

.Cloud {
    width: 120px;
    height: 60px;
    position: absolute;
    background: transparent;
    background-image: url('../../Assets/landscapes/cloud.svg');
    background-size: auto 100%;
    background-position: center center;
    background-repeat: no-repeat;

    @mixin cloudSetter($top,$left,$height,$end) {
        top: $top;
        left: $left;
        height: $height;
        // animation: linear infinite;
        // animation-name: wander;
        // animation-duration: 120s;

        @keyframes wander {
            0% {
              left: $left;
            }
            100% { 
              left: $end; 
            }
        }
    }
   
    &--0 {
        @include cloudSetter(20%,50%,20px,-20vw);
    }

    &--1 {
        @include cloudSetter(40%,100%,16px,-90vw);
    }

    &--2 {
        @include cloudSetter(90%,10%,12px,-30vw);
    }

    &--3 {
        @include cloudSetter(70%,80%,8px,-30vw);
    }

    &--4 {
        @include cloudSetter(10%,20%,6px,-60vw);
    }
}
