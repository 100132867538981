// design tokens come in here, get ready for the ride...

@import '../../../Style/app.scss';

// component style

.Grid {
    width: 100%;
    display: flex;
    top: 0;
    left: 0;
    justify-content: space-around;
    flex-flow: row wrap;
    align-items: stretch;
    @include setWhitespace('padding',null,'x4');
    
    &__column {
        box-sizing: border-box;
        flex: 0 0 div(100%,$theme-grid-columns);
        padding: 0 getWhitespace('padding','x3');
        flex-flow: column;
        
        @mixin growLimit ($columns) {
            flex-grow: $columns;
        }

        &--x1 {
            @include growLimit(0);
        }

        &--x2 {
            @include growLimit(1);
        }

        &--x3 {
            @include growLimit(2);
        }

        &--x4 {
            @include growLimit(3);
        }

        &--x5 {
            @include growLimit(4);
        }

        &--x6 {
            flex-basis: 100%;
            @include growLimit(5);
        }

        &--toolbar {
            min-width: $theme-toolbar-column-size;
            max-width: $theme-toolbar-column-size;
            justify-content: center;
        }

        &--flex {
            display: flex;
            justify-content: stretch;
            align-content: center;
            flex-wrap: wrap;   
        }

        &--clear {
            padding: 0; 
            
        }
    }

    // Grid attributes 

    &--clear {
        &-bottom {
            padding-bottom: 0;
        }

        &-top {
            padding-top: 0;
        }
        
        &-top-bottom {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    @media screen and (max-width: $theme-large-breakpoint) {
        &--row {
            flex-flow: row;
        }
    }
}

@media screen and (min-width: $theme-large-breakpoint) {
    .Grid {
        max-width: 480px;
        margin: auto;
    }
}

