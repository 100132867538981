// design tokens come in here, get ready for the ride...

@import '../../../Style/app';

// component style

.Icon {
    display: inline-block;
    
    &--base {
        @include setColor('color','base','s100');
    }

    &--primary {
        @include setColor('color','primary','s100');
    }

    &--secondary {
        @include setColor('color','secondary','s100');
    }
}