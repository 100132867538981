// design tokens come in here, get ready for the ride...

@import '../../Style/app';

// component style

.Calendar {
    background-color: white;
    display: block;
}

.Content {
    width: 100%;
    max-height: 100%;
    left: 0;
    bottom: 0;
    position: absolute;
    z-index: 1000;
    @include setWhitespace('padding',null,'x4');

    // elements

    &__Wrapper {
        width: 100%;
        overflow: hidden;
        @include setBorderRadius('default');
        @include setColor('background-color','neutral','s100'); 
        @include setElevation('m','base');
    }

    header {
        @include setWhitespace('padding',null,'x5');
        @include setColor('background-color','primary','s100');
        @include setColor('color','neutral','s100');
    }

    &__Body {
        padding: getWhitespace('padding','x5') getWhitespace('padding','x5') 0;
    }

    &__TagWrapper {
        display: flex;
        gap: 4px;
        flex-wrap: wrap;
    }

    &__Actions {
        display: flex;
        justify-content: stretch;
        align-items: center;
        padding: getWhitespace('padding','x5') getWhitespace('padding','x4') getWhitespace('padding','x4');

        & > * {
            flex-basis: 50%;
            flex-grow: 1;
            margin: getWhitespace('margin','x1');
            margin-top: 0;
        }

        & + & {
            padding-top: 0;
            padding-bottom: getWhitespace('padding','x6');
        }
    }

    &__Type {
        position: absolute;
        top: 0;
        @include setWhitespace('right','padding','x8');

        ul {
            li {
                width: $theme-element-fixed-size;
                height: $theme-element-fixed-size;
                border-radius: 24px;
                line-height:  $theme-element-fixed-size;
                display: inline-block;
                margin-left: getWhitespace('margin','x1');
                text-align: center;
                @include setColor('color','light','s100');
                @include setFontSize('content','m');
                @include setColor('background-color','content','incidence');
                @include setElevation('m','base');
            }
        }
    }

    &__Close {
        position: absolute;
        @include setWhitespace('top','padding','x6');
        @include setWhitespace('right','padding','x6');
    }

    // attributes

    @mixin setThemeColor ($colorToken) {
        &--#{$colorToken} {
            header {
                @include setColor('background-color','content',$colorToken);
            }
        }
    }

    @include setThemeColor('incidence');
    @include setThemeColor('event');
    @include setThemeColor('busline');
    @include setThemeColor('renovation');
    @include setThemeColor('block');

    // full state

    &--full {
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
        padding: 0;

        .Content__Wrapper {
            width: 100%;
            height: 100%;
            border-radius: 0;
        }
    }

    // responsive
    @media screen and (min-width: $theme-large-breakpoint) {
        max-width: 480px;
    }

    @media screen and (max-width: $theme-large-breakpoint) {
        overflow: auto;
    }
}