// design tokens come in here, get ready for the ride...

@import '../../Style/app';

// component style

.Feedback {
    * {
        @include setColor(color,'error','s100');
    }
}