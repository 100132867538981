// design tokens come in here, get ready for the ride...

@import '../../../Style/app.scss';

// component style

.Landscape {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: #A5D1DB;

    &--city {
        background-image: url('../../../Assets/landscapes/city.jpg');
        background-size: auto 50vh;
        background-position: center 115%;
        background-repeat: no-repeat;
    }

    &--beach {
        background-image: url('../../../Assets/landscapes/beach.jpg');
        background-size: auto 50vh;
        background-position: center bottom;
        background-repeat: no-repeat;
    }

    &--port {
        background-image: url('../../../Assets/landscapes/port.jpg');
        background-size: auto 50vh;
        background-position: center bottom;
        background-repeat: no-repeat;
    }

    @media screen and (min-width: $theme-large-breakpoint) {
        &--city {
            background-image: url('../../../Assets/landscapes/city-bottom-large.png');
            background-size: auto 340px;
            background-position: center bottom;
            background-repeat: no-repeat;
        }
    }
}

.Sky {
    width: 100vw;
    height: 50vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    background-image: url('../../../Assets/landscapes/sun.svg');
    background-size: auto 60px;
    background-position: 90% 10%;
    background-repeat: no-repeat;
}

.Cloud {
    width: 120px;
    height: 60px;
    position: absolute;
    background: transparent;
    background-image: url('../../../Assets/landscapes/cloud.svg');
    background-size: auto 100%;
    background-position: center center;
    background-repeat: no-repeat;

    @mixin cloudSetter($top,$left,$height,$end) {
        top: $top;
        left: $left;
        height: $height;
        // animation: linear infinite;
        // animation-name: wander;
        // animation-duration: 120s;

        @keyframes wander {
            0% {
              left: $left;
            }
            100% { 
              left: $end; 
            }
        }
    }
   
    &--0 {
        @include cloudSetter(6vh,20vw,28px,-20vw);
    }

    &--1 {
        @include cloudSetter(16vh,80vw,24px,-90vw);
    }

    &--2 {
        @include cloudSetter(20vh,0vw,12px,-30vw);
    }

    &--3 {
        @include cloudSetter(28vh,60vw,8px,-30vw);
    }

    &--4 {
        @include cloudSetter(40vh,0vw,28px,-60vw);
    }
}