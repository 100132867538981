// design tokens come in here, get ready for the ride...

@import '../../../Style/app';

// component style

.Toolbar {
    // width: $theme-toolbar-size;
    padding: getWhitespace('padding','x1') 0;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-flow: column;
    align-content: center;
    align-items: center;
    z-index: 100;

    Button {
        margin-top: getWhitespace('padding','x2');
    }
} 