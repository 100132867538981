// design tokens come in here, get ready for the ride...

@import '../../../../Style/app';

// component style

.Flow {
    @include setColor('background-color','avatar','sky');
    position: relative;

    // p {
    //     @include setFontSize('content','s');
    // }

    [class*='Avatar'] {
        position: absolute;
        bottom: 24px;
        z-index: 2;
        @include setWhitespace('right','padding','x7');
    }

    button {
        width: auto;
        height: auto;
        display: block;

        [class*='Icon'] {
            margin-left: 0;
            float: left;
        }
    }

    // elements 

    &__actions {
        padding: getWhitespace('padding','x7') 0 0 getWhitespace('padding','x7');
    }

    &__top {
        padding: getWhitespace('padding','x2') getWhitespace('padding','x7') getWhitespace('padding','x4');
    }

    &__top, 
    &__actions {
        z-index: 1;
        position: relative;

        * {
            @include setColor('color','base','s100');
        }
    }

    &__bottom,
    &__bottom__success {
        @include setColor('background-color','base','s100');
        padding: getWhitespace('padding','x4') getWhitespace('padding','x7');
        z-index: 1;
        position: relative;
    
        * {
            @include setColor('color','neutral','s100');
        }
    }

    &__bottom__success {
        @include setColor('background-color','success','s100');
    }
}

.Sky {
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    background-image: url('../../../../Assets/landscapes/sun.svg');
    background-size: auto 30px;
    background-position: 95% 10%;
    background-repeat: no-repeat;
    overflow: -moz-hidden-unscrollable;
    z-index: 0;
}

.Cloud {
    width: 120px;
    height: 60px;
    position: absolute;
    background: transparent;
    background-image: url('../../../../Assets/landscapes/cloud.svg');
    background-size: auto 100%;
    background-position: center center;
    background-repeat: no-repeat;

    @mixin cloudSetter($top,$left,$height,$end) {
        top: $top;
        left: $left;
        height: $height;
        // animation: linear infinite;
        // animation-name: wander;
        // animation-duration: 120s;

        @keyframes wander {
            0% {
              left: $left;
            }
            100% { 
              left: $end; 
            }
        }
    }
   
    &--0 {
        @include cloudSetter(20%,50vw,20px,-20vw);
    }

    &--1 {
        @include cloudSetter(30%,100vw,16px,-90vw);
    }

    &--2 {
        @include cloudSetter(50%,10vw,12px,-30vw);
    }

    &--3 {
        @include cloudSetter(20%,80vw,8px,-30vw);
    }

    &--4 {
        @include cloudSetter(10%,20vw,6px,-60vw);
    }
}
    