// design tokens come in here, get ready for the ride...

@import '../../../Style/app';

// component style

.Calendar {
    display: flex;

    &__Date {
        display: flex;
        flex-flow: column;
        border-radius: 4px;
        overflow: hidden;
        flex: 1;
        
        & > div {
            text-align: center;
            display: flex;
            justify-content: center;

            &:first-child {
                @include setColor('color','light','s100');
                @include setFontSize('content','xs');
                @include setWhitespace('padding',null,'x2');
                text-transform: uppercase;
                font-family: $theme-font-family-heading;
                font-weight: $theme-font-weight-heading-bold;
                letter-spacing: .1em;
                background-color: red;
            }

            &:last-child {
                div {
                    flex: 1;
                    justify-content: space-between;
                    text-align: center;
                    @include setWhitespace('padding',null,'x2');
                    @include setColor('background-color','light','s100');
                    
                    &:last-child {
                        @include setColor('background-color','light','s40');
                    }
                }
            }
        }

        &:last-child {
            & > div {
                &:first-child {
                    background-color: #50ce67;
                }
            }
        }
    }

    &__Arrow {
        display: flex;
        align-items: center;
        @include setWhitespace('padding',null,'x3');
    }
}