// design tokens come in here, get ready for the ride...

@import '../../Style/app';

// component style

.Navigation {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    transition: left .5s $theme-transition-base;
    z-index: 1;
    @include setColor('background-color','avatar','sky');
    background-image: url('../../Assets/landscapes/city.jpg');
    background-size: auto 70%;
    background-position: left bottom;
    background-repeat: no-repeat;

    /* elements */ 

    &__drawer {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        @include setWhitespace('padding',null,'x10');
        
        a {
            width: 100%;
            float: left;
            text-decoration: none;
            padding: getWhitespace('padding','x2') 0;
            font-family: getFontFamily('heading');
            @include setFontSize('content','m');
            @include setColor('color','neutral','s100');
            cursor: pointer;

            & > * {
                float: left;
            }

            [class*='Icon'] {
                margin-right: getWhitespace('margin','x2');;
            }
        }
    
        .legal {
            width: 100%;
            position: absolute;
            text-transform: uppercase;
            letter-spacing: .5em;
            @include setWhitespace('left','grid','max-safe-space');
            @include setWhitespace('bottom','grid','max-safe-space');
            @include setFontSize('content','s');
            @include setColor('color','neutral','s100');
        }
    }
}

.Screen {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    overflow: hidden;
    transition: all .5s $theme-transition-base;
    @include setElevation('m','base');
    @include setColor('background-color','neutral','s100');

    &__off {
        @include setBorderRadius('s');
        top: 5%;
        height: 90%;
        left: calc(100% - 82px);
    }

    @media screen and (min-width: $theme-large-breakpoint) {
        &__off {
            @include setBorderRadius('xl');
            top: 2%;
            height: 96%;
            left: 280px;
        }
    }
}

