// typography

$theme-font-size-base: 20px;

// layout 

$theme-ws-base: .25rem;
$theme-grid-columns: 6;
$theme-grid-ws-base: .25rem;
$theme-header-fixed-gap: 60px;

// border

$theme-border-radius-base: 4px;
$theme-border-weight: 4px;

// transitiont

$theme-transition-base: cubic-bezier(0.165, 0.84, 0.44, 1);

// grid

$theme-grid-space-around: 3 * $theme-grid-ws-base 2 * $theme-grid-ws-base;
$theme-grid-column-gutter: $theme-grid-ws-base;
$theme-grid-row-gutter: $theme-grid-ws-base;
$theme-grid-column-size: div(100vw,$theme-grid-columns);
$theme-toolbar-size: 84px;
$theme-toolbar-column-size: 66px;

// element's fixed height

$theme-element-fixed-size: 48px;

// responsive

$theme-large-breakpoint: 910px;