// design tokens come in here, get ready for the ride...

@import '../../../Style/app';

// component style

.Card {
    width: 100%;
    overflow: hidden;
    position: relative;
    @include setBorderRadius('default');
    @include setElevation('default','base');
    @include setColor('background-color','neutral','s100');
    
    header {
        @include setWhitespace('padding',null,'x4');
        @include setColor('background-color','base','s100');
        @include setColor('color','neutral','s100');
        
        // elements

        h3, h4 {
            @include setColor('color','neutral','s100');
            margin-left: getWhitespace('margin','x1');
            
            span {
                width: auto;
                display: inline-block;
                font-size: 1em;
                margin-right: getWhitespace('margin','x3');
                margin-left: -1 * getWhitespace('margin','x1');
            }
        }

        [class*='Button'] {
            position: absolute;
            @include setWhitespace('top','padding','x1');
            @include setWhitespace('right','padding','x1');
            opacity: .6;
        }

        // attributes

        &.Card__header__mandatory {
            @include setColor('background-color','base','s100');
        }

        &.Card__header__optional {
            @include setColor('background-color','primary','s100');
        }

        &.Card__header__info {
            @include setColor('background-color','secondary','s100');
        }
    }

    &__body {
        @include setWhitespace('padding',null,'x3');
        @include setColor('background-color','neutral','s100');
        @include setColor('color','base','s100');

        & > p {
            @include setWhitespace('padding',null,'x2');
            @include setFontSize('content','l');
        }
    }

    &__action_wrapper {
        display: flex;
        justify-content: stretch;
        align-items: center;
        padding-top: getWhitespace('padding','x2');

        & > * {
            flex-basis: 50%;
            flex-grow: 1;
            margin: getWhitespace('padding','x1'); 
            margin-top: 0;
        }

        // case when actions need to be on a single column

        &__column {
            padding: getWhitespace('padding','x1');
            flex-direction: column;

            & > * {
                flex-basis: auto;
                flex-grow: 1;
            }
        }

        &:empty {
            display: none;
        }
    }

    & + & {
        margin-top: getWhitespace('padding','x4');
    }
}