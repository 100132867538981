// design tokens come in here, get ready for the ride...

@import '../../../Style/app';

// component style

.Text {
    position: relative;
    
    @mixin setTextSize ($size) {
        &--#{$size} {
            @include setFontSize('content',$size);
        }
    }

    @include setTextSize('xs');
    @include setTextSize('s');
    @include setTextSize('m');
    @include setTextSize('l');
    @include setTextSize('xl');
    @include setTextSize('xxl');
}