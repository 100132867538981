// T H E M E  F O N T S

$theme-base-font: 'futura-pt';
$theme-primary-font: 'futura-pt';
$theme-secondary-font: 'futura-pt-bold';
$theme-icon-font: null;

// S T Y L E S

$theme-font-family-body: $theme-base-font;
$theme-font-family-heading: $theme-secondary-font;
$theme-font-family-content: $theme-base-font;
$theme-font-family-button: $theme-secondary-font;
$theme-font-family-input: $theme-base-font;

// W E I G H T

$theme-font-weight-body-light: null;
$theme-font-weight-body-regular: 300;
$theme-font-weight-body-bold: null;

$theme-font-weight-heading-light: null;
$theme-font-weight-heading-regular: null;
$theme-font-weight-heading-bold: 700;

$theme-font-weight-content-light: null;
$theme-font-weight-content-regular: 300;
$theme-font-weight-content-bold: null;

$theme-font-weight-button: 900;
