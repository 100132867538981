// design tokens come in here, get ready for the ride...

@import '../../Style/app';

// component style

.Backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 90;
    @include setWhitespace('padding','grid','max-safe-space');
    @include setColor('background-color','base','s80');
    
}