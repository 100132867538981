// design tokens come in here, get ready for the ride...

@import '../../../../../Style/app.scss';

.Point {
    @include setFontSize('content','m');
    position: relative;
    width: 100%;
    margin-bottom: getWhitespace('margin','x4');

    ul {
        padding-left: 12 * $theme-grid-ws-base;

        h6:first-letter {
            text-transform: capitalize;
        }

        button {
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}

.Point__decoration {
    width: 12 * $theme-grid-ws-base;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    
    &:before {
        content: '';
        width: 5 * $theme-grid-ws-base;
        height: 5 * $theme-grid-ws-base;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 4 * $theme-grid-ws-base;
        border: solid 1.5 * $theme-grid-ws-base getColor('base','s100');
        box-sizing: border-box;
        @include setColor('background-color','avatar','sky');
    }

    &:after {
        content: '';
        width: $theme-grid-ws-base;
        height: calc(100% - 36px);
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        border-radius: $theme-grid-ws-base;
        @include setColor('background-color','base','s100');
    }
}