// global colors

$theme-base-color: #333333;
$theme-primary-color: #6C8D96;
$theme-secondary-color: #4E97AC;
$theme-vivid-color: #85A86E;
$theme-neutral-color: #F7F3E3;
$theme-dark-color: #333333;
$theme-error-color: #D56358;
$theme-warning-color: #F4A117;
$theme-info-color: #6C8D96;
$theme-light-color: #FCFBF7;

// content specific

$theme-content-incidence-color: #F4A117;
$theme-content-renovation-color: #333333;
$theme-content-event-color: #3484E5;
$theme-content-block-color: #D56358;
$theme-content-alternative-color: #50CE67;
$theme-content-busline-color: #F4A117;
$theme-content-busline-incidence-color: #F4A117;
$theme-content-busstop-color: #2a2d5e;

// status

$theme-status-error-color: #D56358;
$theme-status-success-color: #50CE67;
$theme-status-warning-color: #F4A117;
$theme-status-info-color: #3484E5;

// social media

$theme-social-facebook-color: #3B5998;
$theme-social-gmail-color: #BB001B;
$theme-social-whatsapp-color: #25D366;

// text 

$theme-text-base-color: $theme-base-color;
$theme-text-neutral-color: $theme-neutral-color;
$theme-text-primary-color: $theme-primary-color;
$theme-text-secondary-color: $theme-secondary-color;
$theme-text-vivid-color: $theme-vivid-color;

// buttons

$theme-button-base-color: $theme-base-color;
$theme-button-primary-color: $theme-primary-color;
$theme-button-secondary-color: $theme-secondary-color;
$theme-button-neutral-color: $theme-neutral-color;
$theme-button-light-color: $theme-light-color;
$theme-button-confirmation-color: $theme-vivid-color;
$theme-button-success-color: $theme-status-success-color;
$theme-button-error-color: $theme-error-color;
$theme-button-warning-color: $theme-warning-color;
$theme-button-info-color: $theme-info-color;
$theme-button-facebook-color: $theme-social-facebook-color;
$theme-button-gmail-color: $theme-social-gmail-color;
$theme-button-whatsapp-color: $theme-social-whatsapp-color;

// background

$theme-background-base-color: #000;
$theme-background-primary-color: #000;
$theme-background-secondary-color: #000;
$theme-background-neutral-color: #000;

// avatar

$theme-avatar-sky-color: #A5D1DB;
$theme-avatar-canary-color: #EDD348;
$theme-avatar-ocean-color: #12A2DC;
$theme-avatar-sunset-color: #8280EA;
$theme-avatar-sunrise-color: #C4544E;
$theme-avatar-grass-color: #85A86E;
