// design tokens come in here, get ready for the ride...

@import '../../Style/app';

// component style

.Chat {
    width: 100%;
    position: relative;

    &__Arrow {
        width: 20px;
        height: 20px;
        position: absolute;
        left: -8px;
        top: 50%;
        margin-top: -10px;
        @include setColor('background-color','base','s100');
        @include setBorderRadius('default');
        // transform-origin: center center;
        transform: rotateZ(-45deg);
    }
    
    &__Wrapper {
        font-family: $theme-font-family-heading;
        font-weight: $theme-font-weight-heading-bold;
        display: flex;
        justify-content: flex-start;
        gap: 12px;
        @include setElevation('default','base');
        @include setColor('background-color','base','s100');
        @include setColor('color','neutral','s100');
        @include setWhitespace('padding',null,'default');
        @include setFontSize('heading','m');
        @include setBorderRadius('default');
    }

    @media screen and (max-width: $theme-large-breakpoint) {
        h4[class] {
            font-size: 1rem !important;
            margin-top: 2px;
        }
    }
}