// design tokens come in here, get ready for the ride...

@import '../../../../Style/app';

// component style

.Footer {
    padding: getWhitespace('padding','x2') 0 getWhitespace('padding','x8') 0;

    // position attributes

    &--sticky {
        position: fixed;
        bottom: 0;
        width: 100%;
    }
}