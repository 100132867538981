body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p, ul, li {
    margin: 0;
    padding: 0;
}

ul {
    list-style: none;
}

button,
input,
textarea {
    border: none;
    appearance: none;
    box-shadow: none;
    resize: none;
}
