// design tokens come in here, get ready for the ride...

@import '../../../Style/app';

.Link {
    text-align: center;
    width: 100%;
    display: block;
    background: none;
    border: none;
    @include setWhitespace('padding',null,'x5');
    @include setColor('color','base','s100');
    @include setFontSize('content','m');
    cursor: pointer;
}