// design tokens come in here, get ready for the ride...

@import '../../Style/app';

// component style

.Feed {
    margin-top: $theme-header-fixed-gap;
    z-index: 200;
    position: relative;

    & > a {
        font-size: inherit;
    }

    @media screen and (min-width: $theme-large-breakpoint) {
        [class*='Grid'] {
            float: left;
        }
    }
}