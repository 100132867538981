// design tokens come in here, get ready for the ride...

@import '../../../Style/app';

// component style

.Switch {
    width: 100%;
    position: relative;
    height: 48px;
    padding: 0 getWhitespace('padding','x4');
    display: flex;
    align-items: center;
    position: relative;
    transition: all .3s $theme-transition-base;
    @include setColor('color','base','s100');
    @include setColor('background-color','base','s5');
    @include setBorderRadius('default');   
    
    &__label {
        width: 80%;
        
        & > div,
        & > span  {
            @include setColor('color','base','s100');
        }

        span {
            font-family: $theme-font-family-button;
            font-weight: $theme-font-weight-button;
            line-height: 1.8rem;
            transition: all .3s $theme-transition-base;
        }

        p, span {
            @include setFontSize('content','l');
        }

        span {
            margin-right: getWhitespace('margin','x2');
        }

        &__icon {
            width: 30px;
            overflow: hidden;
        }
    }

    &__action {
        width: 40px;
        height: 20px;
        border-radius: 24px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: all .3s $theme-transition-base;
        @include setWhitespace('right','padding','x4');
        @include setColor('background-color','base','s20');

        button {
            width: 12px;
            height: 12px;
            padding: 0;
            border: none;
            border-radius: 18px;
            position: absolute;
            top: 4px;
            left: 4px;
            transition: all .3s $theme-transition-base;
            cursor: pointer;
            @include setColor('background-color','base','s100');
        }

        svg {
            opacity: 0;
            font-size: .5em;
            position: absolute;
            top: 25%;
            left: 8px;
        }

        // props

        &--center {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    // position attributes

    & + & {
        margin-top: getWhitespace('margin','x2');
    }

    // shape attributes

    &--border {
        border-top: solid 6px getColor('primary','darken');
        @include setBorderRadius('default');
    }

    // color attributes 

    @mixin setSwitchColor($color) {
        
        @include setColor('background-color',$color,'s5');

        .Switch__label {
            span:first-child,
            &__icon {
                @include setColor('color',$color,'s100');
            }
        }

        .Switch__action {
            @include setColor('background-color',$color,'s20');

            svg {
                opacity: 1;

                path {
                    fill: getColor($color,'s100');
                }
            }

            button {
                @include setColor('background-color',$color,'s100');
                left: 24px;
            }
        }
    }

    // font attributes 

    &--label-small {
        .Switch__label { 
            p, span {
                @include setFontSize('content','m');
            }
        }
    }

    // states

    @mixin setThemeColorForOnState ($id,$colorToken) {
        &--on {
            &--#{$id} {
                @include setSwitchColor($colorToken);
            }
        }
    }

    @include setThemeColorForOnState('success','success');
    @include setThemeColorForOnState('base','base');
    @include setThemeColorForOnState('primary','primary');
    @include setThemeColorForOnState('secondary','secondary');
    @include setThemeColorForOnState('error','error');
    @include setThemeColorForOnState('warning','warning');
    @include setThemeColorForOnState('success','success');
    @include setThemeColorForOnState('light','light');
}