// design tokens come in here, get ready for the ride...

@import '../../../../Style/app.scss';

// component style

.Navigation {
    nav {
        width: 100%;

        a {
            width: 100%;
            display: block;
            padding: getWhitespace('padding','x3') 0 getWhitespace('padding','x3') getWhitespace('padding','x8');
            position: relative;
            @include setColor('color','base','s100');
            cursor: pointer;

            [class*="Icon"] {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);

                &:first-child {
                    left: 0;
                }
            }
        }
    }

    @media screen and (min-width: $theme-large-breakpoint) {
    }
}