// design tokens come in here, get ready for the ride...

@import '../../../../Style/app';

// component style

.Section {
    @include setColor('color','base','s100');
    padding: 0;
    border-radius: 0;

    h3 {
        span {
            margin-right: getWhitespace('margin','x2');
            display: inline-block;
        }
    }

    @mixin setThemeColor ($id,$colorToken,$darkText: false) {
        &--#{$id} {
            @include setColor('background-color',$colorToken,'s100');
            * {
                @include setColor('color','neutral','s100');
            }
            
        }
    }

    @include setThemeColor('error','error',false);
    @include setThemeColor('primary','primary',false);
    @include setThemeColor('base','base',false);
    @include setThemeColor('warning','error',false);
}