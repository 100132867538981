// design tokens come in here, get ready for the ride...

@import '../../../Style/app';

// component style

.Divider {
    width: 100%;
    min-height: getWhitespace('padding','x4');
    display: block;

    // elements

    p, span {
        width: 100%;
        text-align: center;
    }

    // attributes

    &--large {
        min-height: getWhitespace('padding','x10');
    }

    &--medium {
        min-height: getWhitespace('padding','x8');
    }

    &--small {
        min-height: getWhitespace('padding','x4');
    }

    &--x-small {
        min-height: getWhitespace('padding','x2');
    }
}

.Divider__vertical {
    width: getWhitespace('padding','x4');
    min-height: 1px;
    display: block;

    &--large {
        min-width: getWhitespace('padding','x10');
    }

    &--medium {
        min-width: getWhitespace('padding','x6');
    }

    &--small {
        min-width: getWhitespace('padding','x3');
    }
}