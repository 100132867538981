// design tokens come in here, get ready for the ride...

@import '../../../Style/app';

// component style

.Heading {
    position: relative;

    & > a {
        font-size: inherit;
    }

    [class*="Icon"] {
        font-size: .9em;
        padding-right: 2px;
    }
    
    @mixin setThemeColor ($colorToken) {
        &--#{$colorToken} {
            @include setColor('color',$colorToken,'s100');
        }
    }

    @include setThemeColor('base');
    @include setThemeColor('primary');
    @include setThemeColor('secondary');
    @include setThemeColor('light');
    @include setThemeColor('neutral');
    @include setThemeColor('success');
    @include setThemeColor('warning');
    @include setThemeColor('error');
}