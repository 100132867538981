// design tokens come in here, get ready for the ride...

@import '../../../../Style/app';

// component style

.Header {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    
    &__fixed_wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
    }

    @media screen and (min-width: $theme-large-breakpoint) {
        [class*='Grid'] {
            float: left;
        }
    }
}