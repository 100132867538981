// design tokens come in here, get ready for the ride...

@import '../../Style/app';

// component style

.Sheet {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    overflow: hidden;
    box-sizing: border-box;
    transition: opacity 1s $theme-transition-base;
    
    &__wrapper {
        width: 100%;
        height: 100%;
        position: absolute;
        border-radius: getBorder('radius','l') getBorder('radius','l') 0 0;
        opacity: 1;
        transition: opacity 2s $theme-transition-base;
        overflow: hidden auto;
        z-index: 200;
        @include setWhitespace('top','padding','x10');
        @include setElevation('l','base');
        @include setWhitespace('padding',null,'x0');
        @include setColor('background-color','neutral','s100');

        & > button {
            position: absolute;
            top: 0px;
            right: 0px;
            z-index: 1;
            @include setWhitespace('top','padding','x2');
            @include setWhitespace('right','padding','x2');
        }
    }

    &--open {
        opacity: 1;

        .Sheet__wrapper {
            opacity: 1;
            @include setWhitespace('top','padding','x5');
        }
    }
}