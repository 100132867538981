// design tokens come in here, get ready for the ride...

@import '../../../Style/app';

// component style

.Button {
    width: 100%;
    font-family: $theme-font-family-button;
    font-weight: $theme-font-weight-button;
    transition: all .5s $theme-transition-base;
    cursor: pointer;
    outline: none;
    @include setWhitespace('padding',null,'button');
    @include setBorderRadius('default');
    @include setButtonColor('base','active');
    @include setElevation('default','base');
    
    span {
        @include setColor('color','neutral','s100');
        @include setFontSize('content','l');

        // elements

        [class*='Icon'] {
            margin-right: getWhitespace('margin','x2'); 
        }
    }

    &:disabled {
        cursor: default;
    }

    // types 

    &--rounded {
        width: 48px;
        height: $theme-element-fixed-size;
        border-radius: $theme-element-fixed-size;
        position: relative;
        overflow: hidden;
        @include setElevation('','base');

        span {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;

            // elements

            [class*='Icon'] {
                margin-right: 0;
            }

            svg {
                width: 48px;
            }
        }
    }

    &--minimal {
        width: 24px;
        height: 24px;
        padding: 0;
        background-color: transparent !important;
        border-radius: 24px;
        box-shadow: none !important;

        // elements

        span {
            [class*='Icon'] {
                margin: auto;
                align-self: center
            }
        }

        &:active,
        &:focus {
            background-color: transparent;
        }
    }   

    &--wrapper {
        padding: 0;
        box-shadow: none !important;
        background-color: transparent !important;
    }

    // attributes: color

    @mixin setThemeColor ($id,$colorToken,$state,$elevation: false, $darkText: false) {
        &--#{$id} {
            @include setButtonColor($colorToken,$state);
            
            @if $elevation {
                @include setElevation('default',$colorToken);
            }

            @if $darkText {
                span {
                    @include setColor('color','base','s100');
                }
            }
        }
    }

    @include setThemeColor('info','primary','active',true,false);
    @include setThemeColor('primary','primary','active',true,false);
    @include setThemeColor('secondary','secondary','active',true,false);
    @include setThemeColor('base','base','active',true,false);
    @include setThemeColor('neutral','neutral','active',true,true);
    @include setThemeColor('light','light','active',true,true);
    @include setThemeColor('facebook','facebook','active',false,false);
    @include setThemeColor('gmail','gmail','active',false,false);
    @include setThemeColor('whatsapp','whatsapp','active',false,false);
    @include setThemeColor('loading','primary','active',true,false);
    @include setThemeColor('error','error','active',true,false);
    @include setThemeColor('warning','warning','active',true,false);
    @include setThemeColor('confirmation','confirmation','active',true,false);
    @include setThemeColor('success','success','active',true,false);
    @include setThemeColor('sunset','sunset','active',false,false);

    &--no-elevation {
        box-shadow: none !important;
    }
}

