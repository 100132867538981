// design tokens come in here, get ready for the ride...

@import '../../../../Style/app';

// component style

.Input {
    width: 100%;
    margin-bottom: getWhitespace('margin','x3');

    label {
        width: 100%;

        h3 {
            width: 100%;
            position: relative;
            transition: color .3s $theme-transition-base;
            @include setFontSize('content','l');

            [class*='Icon'] {
                position: absolute;
                right: 0;
                @include setWhitespace('bottom','padding','x0');
                @include setColor('color','base','s100');
                @include setFontSize('content','s');
            }
        }

        .Input__sublabel {
            width: 100%;
            position: relative;
            display: block;
            padding: getWhitespace('padding','x0');
            
            span {
                @include setFontSize('content','m');
                max-width: 100%;
                display: block;    
                transition: color .3s $theme-transition-base;

                a {
                    @include setFontSize('content','m');
                    text-decoration: underline;
                }
            }

            & + * {
                margin-top: getWhitespace('margin','x2');
                position: relative;
            }
        }

        input {
            width: 100%;
            height: $theme-element-fixed-size;
            font-family: $theme-font-family-input;
            transition: all .3s $theme-transition-base;
            outline: none;
            box-shadow: none;
            @include setWhitespace('padding',null,'default');
            @include setColor('background-color','light','s100');
            @include setColor('color','base','s100');
            @include setBorderRadius('default');
            @include setFontSize('content','m');

            @include setPlaceholder {
                @include setColor('color','base','s40');
                opacity: 1;
            }

            &:focus {
                box-shadow: inset 0 0 0 $theme-border-weight getColor('base','s10');
            }
        }
    }

    & + & {
        margin-top: getWhitespace('margin','x0');
    }

    &:last-child {
        margin-bottom: 0;
    }

    // states

    @mixin setInputColor($labelColor,$inputColor) {
        label {
            h3,
            span,
            input {
                color: $labelColor !important;
            }

            input {
                box-shadow: inset 0 0 0 $theme-border-weight getColor('base','s10');
            }

            a {
                display: none;
            }
        }
    }

    &--success {
       @include setInputColor(getColor('success','s100'),getColor('success','s10'));
    }

    &--warning {
        @include setInputColor(getColor('warning','s100'),getColor('warning','s10'));
    }

    &--error {
        @include setInputColor(getColor('error','s100'),getColor('error','s10'));
    }
}