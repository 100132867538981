// color

@mixin setColor ($key,$colorToken,$shade) {
    #{$key}: getColor($colorToken,$shade);
}

// whitespace

@mixin setWhitespace ($key,$token,$size) {
    @if($key == 'top' or  $key == 'right' or $key == 'bottom' or $key == 'left') {
        #{$key}: getWhitespace($token,$size);
    }
    @else if($key == 'margin' or $key == 'padding') {
        #{$key}: getWhitespace($key,$size);
    }
}

// font 

@mixin setFontSize ($fontToken,$size) {
   font-size: getFontSize($fontToken,$size);
}

// box shadow

@mixin setElevation ($size,$colorToken) {
    box-shadow: getElevation($size,$colorToken);
}

// border

@mixin setBorderRadius ($size) {
    border-radius: getBorder('radius',$size);
}

// button

@mixin setButtonColor ($colorToken,$state) {
    background-color: getButtonColor($colorToken,$state);
}

// form

@mixin setPlaceholder {
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder{ 
        @content;
    }
    &:-ms-input-placeholder{
        @content;
    }  
}

// theme

@mixin theme($key, $color) {
    @each $theme-name, $theme-color in $themes {
        & {
            #{$key}: map-get(map-get($themes,'light'), $color)
        }
    }
}
