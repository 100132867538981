
body {
    font-family: $theme-font-family-body, sans-serif;
    font-size: $theme-font-size-base;
    background-color: $theme-neutral-color;
    color: $theme-base-color;
    overflow-x: hidden;
}

#root,
.App,
.User,
.Inbox {
    max-width: 100%;
    overflow-x: hidden;
    position: relative;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $theme-font-family-heading, sans-serif;
    font-weight: $theme-font-weight-heading-bold;
    color: $theme-text-base-color;
}

h1 {
    @include setFontSize('heading','xxl');
}

h2 {
    @include setFontSize('heading','xl');
}

h3 {
    @include setFontSize('heading','l');
}

h4 {
    @include setFontSize('heading','m');
}

h5, h6 {
    @include setFontSize('heading','s');
}

p, a, span {
    @include setFontSize('content','m');
    line-height: 1.4em;
}

a {
    color: $theme-text-base-color;
    text-decoration: none;
}

strong {
    @include setFontSize('heading','m');
}