// design tokens come in here, get ready for the ride...

@import '../../../../../Style/app.scss';

.Point {
    @include setFontSize('content','m');
    position: relative;
    margin-bottom: getWhitespace('margin','x4');

    ul {
        padding-left: 12 * $theme-grid-ws-base;
    }

    &__map {
        width: 100%;
        height: 180px;
        overflow: hidden;
        @include setColor('background-color','light','s100');
        @include setBorderRadius('default');
    }
}

.Autocomplete {
    @include setWhitespace('padding',null,'x6');
    @include setBorderRadius('default');
    @include setColor('background-color','light','s100');
    @include setElevation('default','base');
    box-shadow: inset 0 0 0 $theme-border-weight getColor('base','s10');
    width: 100%;
    
    button {
        width: 100%;
        background-color: transparent;
        text-align: left;
        padding: getWhitespace('padding','x3') 0;
        border-bottom: solid 1px getColor('base','s10');
        cursor: pointer;
        @include setFontSize('content','s');

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            border: none;
            padding-bottom: 0;
        }
    }
}

