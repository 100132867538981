// design tokens come in here, get ready for the ride...

@import '../../../Style/app';

// component style

.Tag {
    display: inline-block;
    text-transform: uppercase;
    font-family: $theme-font-family-button;
    font-weight: $theme-font-weight-button;
    padding: getWhitespace('padding','x2') getWhitespace('padding','x3');
    @include setColor('color','light','s100');
    @include setFontSize('heading','xs');
    @include setColor('background-color','base','s100');
    @include setBorderRadius('default');

    span {
        @include setFontSize('heading','s');
        position: relative;
    }

    [class*='Icon'] {
        margin-right: getWhitespace('margin','x2');
        padding-left: 0;
        margin-left: 0;

        &:only-child {
            margin: 0;
        }
    }

    @mixin setThemeColor ($colorToken,$darkText: false) {
        &--#{$colorToken} {
            @include setColor('background-color',$colorToken,'s100');
            // check if dark color is needed
            @if $darkText {
                @include setColor('color','base','s100');
            }
        }
    }

    &--small {
        span {
            @include setFontSize('heading','xs');
        }
    }

    @include setThemeColor('base');
    @include setThemeColor('primary');
    @include setThemeColor('secondary');
    @include setThemeColor('light',true);
    @include setThemeColor('neutral',true);
    @include setThemeColor('success');
    @include setThemeColor('warning');
    @include setThemeColor('error');

    &--blinking {
        animation: blinking 2s infinite;

        @keyframes blinking {
            0% {
                opacity: .5;
            }
            50% {
                opacity: 1;
            }
            100% {
                opacity: .5;
            }
        }
    }
}